import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import Button from 'ui-kit/button/button';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PaymentMethods from 'components/payment-methods/payment-methods.components';

// Pages
import { FailureUpdateProfileModalContent } from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import { accountCreditCardsSelector } from 'state/account/account.selectors';
import { CreditCardPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { membershipUpdateDefaultPaymentMethodRoutine } from 'state/membership/membership.routines';
import { paymentsActions } from 'state/payments/payments.reducers';

// Types
import { PaymentCard } from 'types/easy-refill';

// Styles
import './manage-membership-payment-method-body.style.scss';

interface ManageMembershipPaymentMethodBodyProps {
    isModal?: boolean;
    currentFlow?: 'request-visit' | 'manage-membership' | 'payment-history';
    selectedPayment?: CreditCardPayload;
}

const ManageMembershipPaymentMethodBody = ({
    isModal = false,
    currentFlow,
    selectedPayment
}: ManageMembershipPaymentMethodBodyProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentCard | CreditCardPayload | undefined>(
        selectedPayment || undefined
    );
    const allPaymentData = useSelector(accountCreditCardsSelector);

    const isRequestVisit = currentFlow === 'request-visit';
    const isMembershipSettings = currentFlow === 'manage-membership';
    const isPaymentHistory = currentFlow === 'payment-history';

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0 && (isRequestVisit || isPaymentHistory)
            ? allPaymentData.find((card) => card.defaultCard)
            : allPaymentData?.find((card) => card.defaultMembershipPayment);
    }, [allPaymentData, isRequestVisit, isPaymentHistory]);

    const sortOrder = useMemo(() => {
        return isRequestVisit || isPaymentHistory ? false : true;
    }, [currentFlow]);

    useEffect(() => {
        if (allPaymentData === undefined) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitPaymentChange = () => {
        setIsBusy(true);
        if (isRequestVisit || isPaymentHistory) {
            dispatch(paymentsActions.setSelectedPaymentMethod(selectedPaymentMethod as CreditCardPayload));
            dispatch(
                openModal({
                    showClose: true,
                    bodyContent: (
                        <BirdiModalContent
                            icon={'default'}
                            title={t('modals.paymentMethods.success.title')}
                            body={t('modals.paymentMethods.success.description')}
                        />
                    ),
                    ctas: [
                        {
                            label: t('modals.paymentMethods.success.cta'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            }
                        }
                    ]
                })
            );
        } else if (isMembershipSettings) {
            dispatch(
                membershipUpdateDefaultPaymentMethodRoutine.trigger({
                    data: {
                        ...selectedPaymentMethod,
                        defaultMembershipPayment: true,
                        companyCard: undefined,
                        conversionLink: undefined,
                        lastUsed: undefined,
                        securityCode: undefined
                    },
                    onSuccess: () => {
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'default'}
                                        title={t('modals.paymentMethods.success.title')}
                                        body={t('modals.paymentMethods.success.description')}
                                    />
                                ),
                                onClose: () => {
                                    dispatch(accountGetAllCreditCardsRoutine.trigger());
                                },
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(accountGetAllCreditCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent
                                        content={t(
                                            'pages.profile.membership.manageMembership.modals.paymentMethodChangeError'
                                        )}
                                        area=""
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    };

    const handlePaymentMethodSelection = (selectedPaymentMethod: CreditCardPayload) => {
        setSelectedPaymentMethod(selectedPaymentMethod);
    };

    return (
        <div className="payment-container">
            {allPaymentData && (
                <PaymentMethods
                    showLabel
                    showSelectCardRadioInput
                    addNewPaymentButtonLabel={t('pages.membershipUpgradeYourPlan.actionButtons.paymentMethod')}
                    paymentData={allPaymentData}
                    isProfile={false}
                    onCardSelectionChange={handlePaymentMethodSelection}
                    selectedCardSeqNum={selectedPaymentMethod?.cardSeqNum || defaultCreditCard?.cardSeqNum}
                    buttonVariant="text-blue"
                    hasMembershipFilters={sortOrder}
                />
            )}
            {isModal && (
                <div className="payment-container-action">
                    <Button
                        async
                        isBusy={isBusy}
                        disabled={isBusy}
                        className="sm-full"
                        onClick={handleSubmitPaymentChange}
                        label={t('pages.profile.membership.manageMembership.modals.confirmBtn')}
                        variant="primary"
                        type="button"
                        dataGALocation="ManageMembershipPaymentMethod"
                    />
                    <Button
                        disabled={isBusy}
                        label={t('pages.profile.membership.manageMembership.modals.cancelBtn')}
                        className="md-full md-pad-y-2 text-underline"
                        type="button"
                        variant="link"
                        onClick={() => dispatch(closeModal({}))}
                    />
                </div>
            )}
        </div>
    );
};

export default ManageMembershipPaymentMethodBody;

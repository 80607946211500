import { navigate } from 'gatsby';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI kit
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import LoadingMessage from 'ui-kit/loading-message/loading-message';

// Display components
import ManageGroupCardList from 'display-components/membership/manage-group-card-list';
import {
    MembershipSettingsErrorModal,
    MembershipSettingsInviteSuccessModalContent,
    MembershipSettingsSuccessModalContent
} from 'display-components/membership/modals';
import MembershipSendInvitation, { MemberToAdd } from 'display-components/membership/send-invitation';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import MembershipManageGroupForm, { MembershipManageGroupFormSchema } from 'components/membership-manage-group-form';

import { accountFetchProfileRoutine } from 'state/account/account.routines';
import {
    accountProfileMembershipSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    membershipAddMemberRoutine,
    membershipDetailsRoutine,
    membershipLeavePlanRoutine,
    membershipRemoveFromPlanRoutine,
    membershipRemoveInviteeRoutine,
    membershipSendInviteRoutine
} from 'state/membership/membership.routines';
import {
    membershipDetailSelector,
    membershipIsLoadingDetailsSelector,
    MembershipPlanId
} from 'state/membership/membership.selector';

// State, interfaces and types
import {
    ManageGroupAccountType,
    ManageGroupCardProps,
    MembershipAddMemberResponseData,
    MembershipAddMemberSuccessResponse,
    MembershipInvitationStatus,
    MembershipPlanStatusType
} from 'types/membership';

import { PATIENT_ALREADY_INVITED } from 'util/membership';
import { convertToTitleCase } from 'util/string';

// styles
import './membership-manage-group-list.styles.scss';

interface MembershipManageGroupListProps {
    isInvitee?: boolean;
    planStatus?: MembershipPlanStatusType;
}

const MembershipManageGroupList = (props: MembershipManageGroupListProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const membershipDetails = useSelector(membershipDetailSelector);
    const isLoading = useSelector(membershipIsLoadingDetailsSelector);
    const profileObject = useSelector(accountProfileSelector);
    const profileMembership = useSelector(accountProfileMembershipSelector);
    const profileEPostNum = useSelector(accountProfilEPostPatientNumSelector);

    const { isInvitee, planStatus } = props;
    const isExceededMembers = membershipDetails?.memberAvailableSlots === 0;
    const isCarePlus = membershipDetails?.planId === MembershipPlanId.CARE_PLUS;
    const isPlanActive = planStatus === 'ACTIVE';

    const handleCloseModal = useCallback(
        (isReloadDetails: boolean = false) => {
            if (isReloadDetails)
                dispatch(membershipDetailsRoutine.trigger({ membershipId: profileMembership.membershipID }));
            dispatch(closeModal({}));
        },
        [dispatch, profileMembership]
    );

    const showGenericErrorModal = useCallback(
        (memberName: string, variant: 'sendInvitation' | 'addMember' | 'sendInvitationAssociated' | 'genericError') => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => dispatch(closeModal({})),
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation={variant}
                            memberName={convertToTitleCase(memberName)}
                            t={t}
                            onClose={() => dispatch(closeModal({}))}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const handleSubmitSendInvite = useCallback(
        (selectedMember: MembershipManageGroupFormSchema['selectedMember'], contactMethod?: string) => {
            dispatch(
                membershipSendInviteRoutine.trigger({
                    data: {
                        communicationType: contactMethod,
                        ePostPatientNumber: selectedMember?.ePostPatientNum,
                        holderEPostPatientNumber: profileEPostNum
                    },
                    onSuccess: () => {
                        dispatch(membershipDetailsRoutine.trigger({ membershipId: profileMembership.membershipID }));
                        dispatch(closeModal({}));
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                className: 'modal-membership-add-member',
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'default'}
                                        title={t(
                                            'pages.profile.membership.manageGroupSection.modalSuccessInvite.heading'
                                        )}
                                        body={
                                            <MembershipSettingsInviteSuccessModalContent
                                                memberName={convertToTitleCase(selectedMember?.memberFirstName || '')}
                                                onClose={() => dispatch(closeModal({}))}
                                                t={t}
                                            />
                                        }
                                    />
                                )
                            })
                        );
                    },
                    onFailure: () => {
                        showGenericErrorModal(selectedMember?.memberFirstName || '', 'sendInvitation');
                    }
                })
            );
        },
        [dispatch, handleCloseModal, profileEPostNum, showGenericErrorModal, t]
    );

    const handleOpenSendInviteModal = useCallback(
        (sendInvitationPayload: MembershipManageGroupFormSchema['selectedMember'], isResend: boolean = false) => {
            const payload = {
                ...sendInvitationPayload,
                firstName: sendInvitationPayload?.memberFirstName,
                lastName: sendInvitationPayload?.memberLastName,
                phone: sendInvitationPayload?.phoneNumber
            };

            dispatch(
                openModal({
                    showClose: true,
                    size: 'lg',
                    className: 'modal-membership-add-member',
                    bodyContent: (
                        <BirdiModalContent
                            icon={'none'}
                            title={t(
                                !isResend
                                    ? 'pages.profile.membership.manageGroupSection.modalSendInvitation.heading'
                                    : 'pages.profile.membership.manageGroupSection.modalResendInvitation.heading'
                            )}
                            body={
                                <div className="membership-manage-group-invitation-modal">
                                    <MembershipSendInvitation
                                        dependentData={payload as any}
                                        onSubmit={(contactMethod: string) => {
                                            handleSubmitSendInvite(sendInvitationPayload, contactMethod);
                                        }}
                                        onCancel={() => dispatch(closeModal({}))}
                                        isInvitationModal
                                        isResend={isResend}
                                    />
                                </div>
                            }
                        />
                    )
                })
            );
        },
        [dispatch, handleCloseModal, handleSubmitSendInvite, t]
    );

    const handleSubmitAddMember = useCallback(
        (values: MembershipManageGroupFormSchema) => {
            dispatch(setBusyModal(true));
            const { selectedMember, memberTypeOption } = values;

            const memberTypeNavigationPath = {
                Adult: '/secure/profile/membership/add-adult',
                Minor: '/secure/profile/membership/add-minor'
            };

            // checking adding new member values
            if (memberTypeOption && memberTypeNavigationPath[memberTypeOption]) {
                navigate(memberTypeNavigationPath[memberTypeOption]);
                handleCloseModal();
                return;
            } else {
                if (!profileMembership.membershipID) return;

                const addMemberPayload: MembershipAddMemberResponseData = {
                    holderEPostPatientNumber: profileEPostNum || '',
                    ePostPatientNumber: selectedMember?.ePostPatientNum || '',
                    membershipId: profileMembership.membershipID,
                    dateOfBirth: selectedMember?.memberDob || '',
                    firstName: selectedMember?.memberFirstName || '',
                    lastName: selectedMember?.memberLastName || '',
                    insuranceId: selectedMember?.memberInsuranceId,
                    mobilePhone: selectedMember?.phoneNumber
                };

                dispatch(
                    membershipAddMemberRoutine.trigger({
                        data: addMemberPayload,
                        onSuccess: (response: MembershipAddMemberSuccessResponse) => {
                            if ((selectedMember?.memberAge as number) >= 13) {
                                const sendInvitationPayload = {
                                    ...addMemberPayload,
                                    ePostPatientNum: addMemberPayload.ePostPatientNumber,
                                    phoneNumber: response.mobilePhone,
                                    email: response.emailAddress,
                                    memberFirstName: response.firstName,
                                    memberLastName: response.lastName
                                };

                                // send invitation
                                handleOpenSendInviteModal(sendInvitationPayload as any, false);

                                return;
                            }

                            dispatch(
                                openModal({
                                    showClose: true,
                                    size: 'lg',
                                    className: 'modal-membership-add-member',
                                    bodyContent: (
                                        <BirdiModalContent
                                            icon={'default'}
                                            title={t(
                                                'pages.profile.membership.manageGroupSection.modalSuccessAddMember.heading'
                                            )}
                                            body={
                                                <MembershipSettingsSuccessModalContent
                                                    variation="addMember"
                                                    memberName={convertToTitleCase(response?.firstName || '')}
                                                    onClose={() => handleCloseModal(true)}
                                                    t={t}
                                                />
                                            }
                                        />
                                    )
                                })
                            );
                        },
                        onFailure: (error: any) => {
                            const errorMessage: string = error.messageText;
                            showGenericErrorModal(
                                selectedMember?.memberFirstName || '',
                                errorMessage.includes(PATIENT_ALREADY_INVITED)
                                    ? 'sendInvitationAssociated'
                                    : 'addMember'
                            );
                        }
                    })
                );
            }
        },
        [dispatch]
    );

    const handleAddMemberModal = () => {
        dispatch(
            openModal({
                showClose: true,
                onClose: handleCloseModal,
                size: 'lg',
                className: 'modal-membership-add-member',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('pages.profile.membership.manageGroupSection.modalAddMember.heading')}
                        body={
                            <MembershipManageGroupForm
                                onSubmit={handleSubmitAddMember}
                                onCancel={handleCloseModal}
                                defaultValues={{
                                    selectedMember: undefined,
                                    memberTypeOption: undefined
                                }}
                            />
                        }
                    />
                )
            })
        );
    };

    const handleSubmitRemoveMember = (ePostPatientNum: string, membershipId: string) => {
        dispatch(setBusyModal(true));
        dispatch(
            membershipRemoveFromPlanRoutine.trigger({
                payload: {
                    ePostPatientNum,
                    membershipId
                },
                onSuccess: () => {
                    dispatch(closeModal({}));
                    dispatch(membershipDetailsRoutine.trigger({ membershipId: profileMembership.membershipID }));
                },
                onFailure: () => {
                    dispatch(closeModal({}));
                    showGenericErrorModal('', 'genericError');
                }
            })
        );
    };

    const handleRemoveMember = (values: any) => {
        dispatch(
            openModal({
                showClose: false,
                onClose: handleCloseModal,
                size: 'lg',
                type: 'danger',
                contentClassName: 'modal-content-padding',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.membership.manageGroupSection.modalRemoveMember.heading')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('pages.profile.membership.manageGroupSection.modalRemoveMember.message')}
                    />
                ),
                ctas: [
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalRemoveMember.ctaCancel'),
                        variant: 'link',
                        onClick: () => handleCloseModal(false)
                    },
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalRemoveMember.ctaContinue'),
                        variant: 'primary',
                        async: true,
                        onClick: () => handleSubmitRemoveMember(values.ePostPatientNum, values.membershipId)
                    }
                ]
            })
        );
    };

    const handleSubmitLeavePlan = (ePostPatientNum: string) => {
        dispatch(setBusyModal(true));
        dispatch(
            membershipLeavePlanRoutine.trigger({
                payload: {
                    ePostPatientNum
                },
                onSuccess: () => {
                    dispatch(closeModal({}));
                    dispatch(accountFetchProfileRoutine.trigger());
                },
                onFailure: () => {
                    dispatch(closeModal({}));
                    showGenericErrorModal('', 'genericError');
                }
            })
        );
    };

    const handleLeavePlan = (values: any) => {
        dispatch(
            openModal({
                showClose: false,
                onClose: handleCloseModal,
                size: 'lg',
                type: 'danger',
                contentClassName: 'modal-content-padding',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.membership.manageGroupSection.modalLeavePlan.heading')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('pages.profile.membership.manageGroupSection.modalLeavePlan.message', {
                            ownerName: convertToTitleCase(membershipDetails?.planOwnerName || '')
                        })}
                    />
                ),
                ctas: [
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalLeavePlan.ctaCancel'),
                        variant: 'link',
                        onClick: () => handleCloseModal(false)
                    },
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalLeavePlan.ctaContinue'),
                        variant: 'primary',
                        async: true,
                        onClick: () => handleSubmitLeavePlan(values.ePostPatientNum)
                    }
                ]
            })
        );
    };

    const handleSubmitRemoveInvitee = (ePostPatientNumber: string) => {
        dispatch(
            membershipRemoveInviteeRoutine.trigger({
                ePostPatientNumber,
                ownerMembershipId: profileMembership.membershipID,
                onSuccess: () => {
                    handleCloseModal(true);
                },
                onFailure: () => {
                    showGenericErrorModal('', 'genericError');
                }
            })
        );
    };
    const handleRemoveInvitee = (values: any) => {
        dispatch(
            openModal({
                showClose: false,
                onClose: handleCloseModal,
                size: 'lg',
                type: 'danger',
                contentClassName: 'modal-content-padding',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.membership.manageGroupSection.modalRemoveInvitee.heading')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('pages.profile.membership.manageGroupSection.modalRemoveInvitee.message')}
                    />
                ),
                ctas: [
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalRemoveInvitee.ctaCancel'),
                        variant: 'link',
                        onClick: () => handleCloseModal()
                    },
                    {
                        label: t('pages.profile.membership.manageGroupSection.modalRemoveMember.ctaContinue'),
                        variant: 'primary',
                        onClick: () => handleSubmitRemoveInvitee(values.ePostPatientNum)
                    }
                ]
            })
        );
    };

    /**
     * Get the index of the account type
     * Revisit this if we decide we want to change the values we get from the details api (/api/Membership/{membershipId}/details).
     * Currently, we get a string value back from the api but the modal element(s) expect a number. (see MembershipSendInvitation :: MembershipMemberDetails)
     *
     * @param accountType
     * @returns number
     */
    const getAccountTypeIndex = (accountType: string): number => {
        const accountTypes = ['Caregiver', 'Minor', 'Adult', 'Pet'];
        return accountTypes.indexOf(accountType);
    };

    const membersMap = (membershipDetails?.members || []).map((member) => {
        const isAccountOwner = profileEPostNum === member.epostPatientNum;
        const isPlanUser = membershipDetails?.epostPatientNum === member.epostPatientNum;
        const isPlanOwner = membershipDetails?.isPlanOwner && isPlanUser;
        const isInvitedPlan = isInvitee && isPlanUser;

        const accountType =
            isPlanOwner || isInvitedPlan
                ? (t('pages.profile.membership.manageGroupSection.planOwner') as 'Plan Owner')
                : member.accountType;

        const currentUserSuffix =
            isAccountOwner && !isPlanOwner ? `(${t('pages.profile.membership.manageGroupSection.currentUser')})` : '';
        const memberName = `${convertToTitleCase(member.firstName)} ${convertToTitleCase(
            member.lastName
        )} ${currentUserSuffix}`;

        const isStatusPending = ['pending', 'expired', 'declined'].includes(member.status.toLowerCase());
        const classNames = isStatusPending ? 'manage-group-card__pending-status' : '';

        return {
            membershipId: member.membershipId,
            ePostPatientNum: member.epostPatientNum,
            accountType,
            memberName,
            firstName: member.firstName,
            lastName: member.lastName,
            isInvitee,
            invitationStatus: member.status.toLowerCase() as MembershipInvitationStatus,
            isPlanOwner: isAccountOwner && isPlanOwner,
            onRemoveMember:
                member.status.toLocaleLowerCase() !== 'active'
                    ? () => handleRemoveInvitee({ ePostPatientNum: member.epostPatientNum })
                    : () =>
                          handleRemoveMember({
                              ePostPatientNum: member.epostPatientNum,
                              memberName: member.firstName,
                              membershipId: member.membershipId
                          }),
            onLeavePlan:
                isInvitee && isAccountOwner
                    ? () => handleLeavePlan({ ePostPatientNum: profileObject?.epostPatientNum })
                    : undefined,
            onInvitePlan:
                member.status.toLowerCase() === 'active'
                    ? undefined
                    : () =>
                          // resend invite
                          handleOpenSendInviteModal(
                              {
                                  holderEPostPatientNumber: profileEPostNum,
                                  ePostPatientNum: member?.epostPatientNum || '',
                                  membershipId: member?.membershipId,
                                  accountType: getAccountTypeIndex(member?.accountType) || 0,
                                  memberName: member?.firstName || '',
                                  memberFirstName: member.firstName || '',
                                  memberLastName: member?.lastName || '',
                                  phoneNumber: member?.phoneNumber,
                                  email: member?.email
                              },
                              true
                          ),
            profileEPostNum: Number(profileEPostNum || 0),
            profileMembershipId: profileMembership.membershipID,
            className: classNames
        };
    });

    const activeMembers: ManageGroupCardProps[] = membersMap.filter((member) =>
        ['active', 'accepted'].includes(member.invitationStatus)
    );

    const pendingMembers: ManageGroupCardProps[] = membersMap.filter((member) =>
        ['pending', 'expired', 'declined'].includes(member.invitationStatus)
    );

    const renderInfoText = (isInvitee: boolean | undefined, isExceededMembers: boolean, isCarePlus: boolean) => {
        if (isInvitee) {
            return <p>{t('pages.profile.membership.manageGroupSection.infoTextInvitee')}</p>;
        }

        if (isCarePlus && !isExceededMembers) {
            return <p>{t('pages.profile.membership.manageGroupSection.infoText')}</p>;
        }

        return null;
    };

    const infoBlurb = renderInfoText(isInvitee, isExceededMembers, isCarePlus);

    const renderMembersCount = (
        activeMembers: ManageGroupCardProps[],
        pendingMembers: ManageGroupCardProps[],
        membersAvailable: number | undefined
    ) => {
        const activeMembersCount = activeMembers?.length || 0;
        const pendingMembersCount = pendingMembers?.length || 0;

        const messageKey =
            pendingMembersCount > 0
                ? 'pages.profile.membership.manageGroupSection.membersCountInvited'
                : 'pages.profile.membership.manageGroupSection.membersCount';

        const availableSlots = membersAvailable || 0;
        const hasAvailableSlots = availableSlots > 1 || availableSlots === 0;

        const message = t(messageKey, {
            members: activeMembersCount,
            invited: pendingMembersCount,
            slots: membersAvailable,
            hasManySlots: hasAvailableSlots ? 's' : '',
            hasManyMembers: activeMembersCount > 1 ? 's' : ''
        });

        return sanitizeHtml(message, { allowedTags: ['i'] });
    };

    if (isLoading) {
        return (
            <div className="membership-manage-group-list">
                <LoadingMessage
                    text={t(`pages.profile.membership.manageGroupSection.loadingSection`)}
                    isVisible={isLoading}
                />
            </div>
        );
    }

    return (
        <div className="membership-manage-group-list">
            <div className="membership-manage-group-listItems">
                {!isInvitee && isCarePlus && (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: renderMembersCount(
                                activeMembers,
                                pendingMembers,
                                membershipDetails?.memberAvailableSlots
                            )
                        }}
                    ></span>
                )}
                {isInvitee && <ManageGroupCardList payload={membersMap} />}
                {!isInvitee && <ManageGroupCardList payload={activeMembers} />}

                {!isInvitee && pendingMembers.length > 0 && <ManageGroupCardList payload={pendingMembers} />}

                {!isExceededMembers && !isInvitee && (
                    <div className="membership-manage-group-action">
                        <Button
                            label={t('pages.profile.membership.manageGroupSection.addMember')}
                            type="button"
                            variant="link"
                            plusIcon
                            IconType="secondary"
                            className="btn-dashed"
                            onClick={handleAddMemberModal}
                        />
                    </div>
                )}
            </div>

            {infoBlurb && <div className="membership-manage-group-information">{infoBlurb}</div>}

            {/* DRX-2544: Hidden for the future releases */}
            {/* {isInvitee && (
                <div className="membership-manage-group-action-leavePlan">
                    <Button
                        label={t('pages.profile.membership.manageGroupSection.leavePlan')}
                        type="button"
                        variant="text"
                        className="btn-text-blue btn-underline"
                        onClick={handleLeavePlan}
                    />
                </div>
            )} */}

            {!isInvitee && !isCarePlus && isPlanActive && (
                <p className="membership-manage-group-list__upgrade-plan">
                    {t('pages.profile.membership.manageGroupSection.upgradePlan')}
                    <Link
                        to="/secure/profile/membership/upgrade/"
                        label={t('pages.profile.membership.manageGroupSection.upgradePlanTextLink')}
                        dataGALocation="MembershipSettings"
                    />
                </p>
            )}
        </div>
    );
};

export default MembershipManageGroupList;

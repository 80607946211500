import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMessage from 'ui-kit/loading-message/loading-message';

import CardYourPlan from 'display-components/card-your-plans';
import ManageMembership from 'display-components/manage-membership';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
// Components
import ProfileLayout from 'components/layouts/profile/profile.layout';
import ManageMembershipPaymentMethodBody from 'components/manage-membership/manage-membership-payment-method-body';
import MembershipInviteSection from 'components/membership-invite-section';
import MembershipManageGroupList from 'components/membership-manage-group-list';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountProfileMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
// Types
// Redux
import { openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetRacesRoutine
} from 'state/membership-registration/membership-registration.routines';
import {
    membershipRegistrationEthnicitiesListSelector,
    membershipRegistrationGendersListSelector,
    membershipRegistrationRacesListSelector
} from 'state/membership-registration/membership-registration.selectors';
import { MembershipPlanStatusEnum } from 'state/membership/membership.helpers';
import { membershipDetailsRoutine, membershipGetInvitationsRoutine } from 'state/membership/membership.routines';
import {
    membershipDetailSelector,
    membershipInvitationsSelector,
    membershipIsInviteeSelector,
    membershipIsOnDemandSelector
} from 'state/membership/membership.selector';

import { MembershipInvitationsResponse } from 'types/membership';

// Styles
import './membership-page.style.scss';

const Membership: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);
    const paymentData = useSelector(accountCreditCardsSelector);
    const membershipDetails = useSelector(membershipDetailSelector);
    const profileMembership = useSelector(accountProfileMembershipSelector);
    const userIsInvitee = useSelector(membershipIsInviteeSelector);
    const userIsTelemedicine = useSelector(membershipIsOnDemandSelector);
    const pendingInvite = useSelector(membershipInvitationsSelector);

    const gendersList = useSelector(membershipRegistrationGendersListSelector);
    const racesList = useSelector(membershipRegistrationRacesListSelector);
    const ethnicitiesList = useSelector(membershipRegistrationEthnicitiesListSelector);

    const isProfileLoaded = profileObject !== undefined;
    const invites = pendingInvite?.invites.filter(
        (invite) => invite.status === 0 || invite.status === 2
    ) as unknown as MembershipInvitationsResponse;
    const userHasPendingInvite = Array.isArray(invites) && invites.length > 0;

    /**
     * DRX-2397: As this values are used on Add Member flow, is
     * mandatory to have those values loaded before the user get
     * into the add member flow.
     */
    useEffect(() => {
        if ([gendersList, racesList, ethnicitiesList].some((list) => list.length === 0)) {
            dispatch(membershipRegistrationGetGendersRoutine.trigger());
            dispatch(membershipRegistrationGetRacesRoutine.trigger());
            dispatch(membershipRegistrationGetEthnicitiesRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(membershipGetInvitationsRoutine.trigger());
    }, [dispatch]);

    const onChangePaymentMethod = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'modal-membership-payment',
                size: 'lg',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('pages.profile.membership.manageMembership.modals.changePaymentTitle')}
                        subTitle={t('pages.profile.membership.manageMembership.modals.changePaymentSubtitle')}
                        body={<ManageMembershipPaymentMethodBody isModal currentFlow="manage-membership" />}
                    />
                )
            })
        );
    };

    const activeCreditCards = useMemo(() => {
        return paymentData?.filter((card) => card.cardActive);
    }, [paymentData]);

    const primaryCard = useMemo(() => {
        return activeCreditCards?.find((card) => card.defaultMembershipPayment);
    }, [activeCreditCards]);

    const sections = useMemo(() => {
        if (!isProfileLoaded || !membershipDetails) {
            const loadingSection = {
                children: (
                    <LoadingMessage
                        isVisible
                        text={t(`pages.profile.membership.profileLoadingSection.loadingMessage`)}
                    />
                ),
                suppressChildrenContainer: true
            };

            return [loadingSection];
        }

        if (!membershipDetails) return false;

        const pendingInviteSection = {
            children: <MembershipInviteSection />,
            suppressChildrenContainer: true
        };

        const cardYourPlan = {
            children: (
                <CardYourPlan
                    {...membershipDetails}
                    isInvitee={userIsInvitee}
                    isTelemedicine={userIsTelemedicine}
                    onUpgradePlan={() => navigate('/secure/profile/membership/upgrade')}
                    onMakePayment={() => navigate('/secure/profile/payment/history')}
                />
            )
        };

        if (membershipDetails.planStatus === MembershipPlanStatusEnum.Canceled) {
            return [cardYourPlan];
        }

        const manageGroupSection = {
            heading: t(`pages.profile.membership.manageGroupSection.heading`),
            children: <MembershipManageGroupList isInvitee={userIsInvitee} planStatus={membershipDetails.planStatus} />,
            suppressChildrenContainer: true
        };

        if (userIsInvitee && !userIsTelemedicine) {
            return [cardYourPlan, manageGroupSection];
        }

        const manageMembership = {
            heading: t('pages.profile.membership.manageMembership.heading'),
            children: (
                <ManageMembership
                    creditCardInfo={primaryCard}
                    onChangePaymentMethod={onChangePaymentMethod}
                    paymentFrequency={membershipDetails.paymentFrequency || ''}
                    planStatus={membershipDetails.planStatus}
                />
            ),
            suppressChildrenContainer: true
        };

        const commonItems = [cardYourPlan, manageGroupSection, manageMembership];

        if (userIsTelemedicine && userHasPendingInvite) {
            return [cardYourPlan, pendingInviteSection];
        }

        if (userIsTelemedicine && !userHasPendingInvite) {
            return [cardYourPlan];
        }

        return [...commonItems];
    }, [
        primaryCard,
        membershipDetails,
        userIsInvitee,
        userIsTelemedicine,
        pendingInvite,
        isProfileLoaded,
        onChangePaymentMethod,
        t
    ]);

    useEffect(() => {
        if (!isProfileLoaded) return;

        // redirection to family account for users without hasMembership
        if (!profileMembership.hasMembership && isProfileLoaded) {
            navigate('/secure/profile/family-account');
            return;
        }

        if (profileMembership.hasMembership) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [dispatch, isProfileLoaded]);

    // Verification that when the user leaves the plan as a guest, the details request must be caller again
    // because the profile's membershipId is changed
    useEffect(() => {
        if (profileObject === undefined || !membershipDetails) return;

        if (profileObject.membershipID !== membershipDetails.memberId.toString()) {
            dispatch(membershipDetailsRoutine.trigger({ membershipId: profileObject.membershipID }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileObject, membershipDetails]);

    if (!ENABLE_MEMBERSHIP) {
        navigate('/secure/profile/family-account');
        return null;
    }

    return (
        <div className="membership-page">
            <ProfileLayout
                eyebrowText={t(`pages.profile.eyebrowText`)}
                title={t(`pages.profile.membership.title`)}
                sections={sections}
            />
        </div>
    );
};

export default Membership;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
